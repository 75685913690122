var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Page',{class:_vm.backgroundFilter,attrs:{"id":"page"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{ref:"carSearchGrid",attrs:{"id":"search-page-container"}},[_c('Section',{attrs:{"id":"search-form"}},[_c('SearchForm',{key:_vm.searchformKey,attrs:{"segmentList":_vm.getDropdowns['segment'],"modelList":_vm.getDropdowns['model'],"fuelList":_vm.getDropdowns['fuel'],"financeProps":_vm.financeItems,"transmissionList":_vm.getDropdowns['transmission'],"resumeSearch":_vm.handleTags,"usedCars":""},on:{"onSearch":_vm.search,"filterCounter":_vm.filterCounter}})],1),(!_vm.getLoadingState && _vm.used.length > 0)?_c('div',[_c('Section',{attrs:{"id":"summary"}},[_c('FilterCarProduct',{attrs:{"resultCount":_vm.getTotal,"listOrder":_vm.getSortList},on:{"onSort":_vm.sortSearch}})],1),(_vm.used)?_c('Section',{attrs:{"id":"results"}},[_vm._l((_vm.used.slice(0, 5)),function(v,i){return _c('UsedSearchVehicleResultCard',{key:v.vin + i + v.model + 1,attrs:{"vehicle":v,"id":v.vin,"usedCar":""},on:{"onClickResult":function($event){return _vm.onClick(1 + i, v.vin)}}})}),(_vm.used.length >= 5)?_c('CardSeparator',{attrs:{"customClass":"light-bg highlight-title","imgURL":_vm.$t('resources.images.searchpage.separatorCard1') ||
                  require('../../../assets/images/valet-icon.svg'),"title":_vm.$t('pages.searchpage.cards.evaluation.title'),"description":"","buttonLabel":_vm.$t('pages.searchpage.cards.evaluation.btnLabel'),"buttonAction":"Retake Evaluation","primary":""}}):_vm._e(),_vm._l((_vm.used.slice(5, 10)),function(v,i){return _c('UsedSearchVehicleResultCard',{key:v.vin + i + v.model + 2,attrs:{"vehicle":v,"id":v.vin,"usedCar":""},on:{"onClickResult":function($event){return _vm.onClick(6 + i, v.vin)}}})}),(_vm.used.length >= 10)?_c('CardSeparator',{attrs:{"customClass":"dark-bg","imgURL":_vm.$t('resources.images.searchpage.separatorCard2') ||
                  require('../../../assets/images/car-key.svg'),"title":_vm.$t('pages.searchpage.cards.vehicleKey.title'),"description":""}}):_vm._e(),_vm._l((_vm.used.slice(10, 15)),function(v,i){return _c('UsedSearchVehicleResultCard',{key:v.vin + i + v.model + 3,attrs:{"vehicle":v,"id":v.vin,"usedCar":""},on:{"onClickResult":function($event){return _vm.onClick(11 + i, v.vin)}}})}),(_vm.used.length >= 15)?_c('CardSeparator',{attrs:{"customClass":"dark-bg warranty-card","imgURL":_vm.$t('resources.images.searchpage.separatorCard3') ||
                  require('../../../assets/images/car-magnifying-glass.svg'),"title":_vm.$t('pages.searchpage.cards.warranty.title'),"description":_vm.$t('pages.searchpage.cards.warranty.description'),"buttonLabel":_vm.$t('pages.searchpage.cards.warranty.btnLabel'),"buttonAction":"contactPanel","buttonIcon":"arrowRightWhite.svg"}}):_vm._e(),_vm._l((_vm.used.slice(15)),function(v,i){return _c('UsedSearchVehicleResultCard',{key:v.vin + i + v.model + 4,class:_vm.used.slice(15).length !== 5 ? 'test' : '',attrs:{"vehicle":v,"id":v.vin,"usedCar":""},on:{"onClickResult":function($event){return _vm.onClick(16 + i, v.vin)}}})}),(_vm.used.length >= 20)?_c('CardSeparator',{attrs:{"customClass":"light-bg","imgURL":_vm.$t('resources.images.searchpage.separatorCard4') ||
                  require('../../../assets/images/auto-finance.svg'),"title":_vm.$t('pages.searchpage.cards.howItWorks.title'),"description":_vm.$t('pages.searchpage.cards.howItWorks.description'),"buttonLabel":_vm.$t('pages.searchpage.cards.howItWorks.btnLabel'),"buttonAction":"HowItWorksPage","primary":""}}):_vm._e()],2):_vm._e(),_c('div',{class:!_vm.anyModalOpened ? 'mobile-btn' : 'mobile-btn-under-panel'},[_c('Button',{staticStyle:{"height":"60px"},attrs:{"type":"primary","fill":"","uppercase":"","label":_vm.filterLength === 0
                  ? 'Filtros'
                  : 'Filtros (' + _vm.filterLength + ')',"filename":"filter-icon.png"},on:{"onClick":_vm.openFilterPanel}})],1),_c('Section',{attrs:{"id":"pagination"}},[_c('Pagination',{attrs:{"used":""}})],1)],1):_vm._e(),(_vm.getLoadingState)?_c('div',{staticClass:"loading"},[_c('Button',{attrs:{"loading":"","border":false}})],1):_vm._e(),(!_vm.getLoadingState && _vm.used.length === 0)?_c('div',[_c('div',{staticClass:"no-results"},[_c('Label',{staticClass:"no-results--title",attrs:{"color":"primary","uppercase":true}},[_vm._v(" "+_vm._s(_vm.$t("pages.searchpage.emptyResults.title"))+" ")]),_c('div',{staticClass:"no-results--info"},[_c('Label',{staticClass:"no-results--paragraph",attrs:{"size":"small"}},[_vm._v(" "+_vm._s(_vm.$t("pages.searchpage.emptyResults.description"))+" ")])],1),_c('Button',{staticClass:"no-results--button",attrs:{"fill":"","label":_vm.$t('pages.searchpage.emptyResults.btnLabel')},on:{"onClick":_vm.redirectToContactPage}})],1),_c('Section',{staticClass:"similar--section",attrs:{"id":"similar"}},[_c('div',{staticClass:"similar-container--section"},[_c('SectionHeader',{staticClass:"similar-container--header",attrs:{"type":"primary","titleSize":"large","title":_vm.$t('pages.searchpage.emptyResults.similarVehicles')}}),_c('div',{staticClass:"similar--container"},_vm._l((_vm.getSimilarVehicles.slice(0, 4)),function(v,i){return _c('UsedSearchVehicleResultCard',{key:v.vin + i + v.model + 5,staticClass:"similar-card--item",attrs:{"vehicle":v}})}),1)],1)])],1):_vm._e(),_c('Section',{attrs:{"id":"content-block"}},[_c('ContentBlock',{attrs:{"content":_vm.contentBlock,"html":""}})],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }